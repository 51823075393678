import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Searching and sorting analysis on weather data</h1>
            <h4>September 2015 - March 2016</h4>
            <p>
                As a high school student, I was a professional Magic: The Gathering player, which also transitioned into me selling my own cards on an online store. After struggling with keeping inventory, I built a Magic: The Gathering inventory manager that you could input all of your cards into, including search queries based on anything on a card. Additionally, I built features such as being able to submit a list of cards for a  deck and determine which cards I already had and which I still needed and a GUI to show all of the information. I wrote the whole project using Java, along with an Apache database, and using the Netbeans IDE to build my own GUI. 
            </p>
        </ProjectPage>
    </div>)
}

export default IndexPage
